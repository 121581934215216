<template>
  <div
    class="
      create-store-application-forms
      sign-in
      d-flex
      flex-column
      align-center
      mx-4
    "
  >
    <div class="title mt-16 mb-8 d-flex justify-center">
      <div class="text-h4">
        {{ $t("__create_store_application_forms_title") }}
      </div>
      <v-btn class="refresh-btn mr-4" @click="loadCreateStoreApplicationForms">
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
    </div>
    <v-card class="ma-2" elevation="0" max-width="1000" width="100%">
      <v-card-text>
        <v-data-table :headers="tableHeaders" :items="formList">
          <template v-slot:[`item.actions`]="{ item }">
            <!-- edit button -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="ma-2"
                  small
                  v-if="
                    item['status'] === 'unsubmitted' ||
                      item['status'] === 'rejected'
                  "
                  v-bind="attrs"
                  v-on="on"
                  @click="toEditForm(item['id'])"
                >
                  mdi-pencil
                </v-icon>
              </template>
              <span>{{
                item["status"] === "unsubmitted" ? $t("__edit") : $t("__refill")
              }}</span>
            </v-tooltip>
            <!-- upload photo button -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="ma-2"
                  v-if="item['status'] === 'unsubmitted'"
                  small
                  v-bind="attrs"
                  v-on="on"
                  @click="toUploadPhoto(item['id'])"
                >
                  mdi-upload
                </v-icon>
              </template>
              <span>{{ $t("__upload_photo") }}</span>
            </v-tooltip>
            <!-- submit button -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="ma-2"
                  small
                  v-if="item['status'] === 'unsubmitted' && item['is_filled']"
                  v-bind="attrs"
                  v-on="on"
                  @click="toSubmitForm(item['id'])"
                >
                  mdi-check
                </v-icon>
              </template>
              <span>{{ $t("__submit") }}</span>
            </v-tooltip>
            <!-- delete button -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="ma-2"
                  small
                  v-if="item['status'] === 'unsubmitted'"
                  v-bind="attrs"
                  v-on="on"
                  @click="openDeleteDialog(item['id'])"
                >
                  mdi-delete
                </v-icon>
              </template>
              <span>{{ $t("__delete") }}</span>
            </v-tooltip>
            <!-- reply description button -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="ma-2"
                  small
                  v-if="
                    (item['status'] === 'accepted' ||
                      item['status'] === 'rejected') &&
                      item['reply_description']
                  "
                  v-bind="attrs"
                  v-on="on"
                  @click="toShowReplyDescription(item['id'])"
                >
                  mdi-message-text
                </v-icon>
              </template>
              <span>{{ $t("__reply_description") }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog v-model="deleteDialog" max-width="290">
      <v-card>
        <v-card-title class="text-h5 red--text">
          {{ $t("__confirm_delete") }}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="grey darken-1" text @click="deleteDialog = false">
            {{ $t("__cancel") }}
          </v-btn>

          <v-btn
            color="red darken-1"
            text
            @click="
              deleteDialog = false;
              toDeleteForm(cacheID);
            "
          >
            {{ $t("__confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- reply description -->
    <v-dialog v-model="descriptionDialog" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{ $t("__reply_description") }}
        </v-card-title>

        <v-card-text v-html="descriptionDialogText"> </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="descriptionDialog = false">
            {{ $t("__confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { format } from "date-fns";
import { userAPI } from "@/plugins/service";

export default {
  name: "CreateStoreApplicationForms",
  computed: {
    ...mapGetters({
      formIDs: "createStoreApplicationForms/createStoreApplicationFormIDs",
      forms: "createStoreApplicationForms/createStoreApplicationForms"
    }),
    formList() {
      this.formIDs;
      this.forms;
      const list = [];
      for (const formID of this.formIDs) {
        const form = this.forms[formID];
        const isFilled = this.checkFormIsFilled(form);
        list.push({
          id: formID,
          store_name: form["store_name"],
          apply_time: this.formatTime(form["create_time"]),
          update_time: this.formatTime(form["update_time"]),
          is_filled: isFilled,
          application_application_fill_status: this.$t(
            `__application_fill_status_${isFilled ? "filled" : "unfilled"}`
          ),
          status: form["status"],
          application_application_status: this.$t(
            `__application_status_${form["status"]}`
          ),
          submit_time: this.formatTime(form["submit_time"]),
          reply_time: this.formatTime(form["reply_time"]),
          reply_description: form["reply_description"]
        });
      }
      return list;
    }
  },
  data() {
    return {
      status: "",
      deleteDialog: false,
      descriptionDialog: false,
      descriptionDialogText: "",
      cacheID: "",
      tableHeaders: [
        {
          text: this.$t("__store_name"),
          align: "start",
          value: "store_name"
        },
        {
          text: this.$t("__application_fill_status"),
          align: "start",
          value: "application_application_fill_status"
        },
        {
          text: this.$t("__application_status"),
          align: "start",
          value: "application_application_status"
        },
        {
          text: this.$t("__submit_time"),
          align: "start",
          value: "submit_time"
        },
        {
          text: this.$t("__reply_time"),
          align: "start",
          value: "reply_time"
        },
        {
          align: "end",
          sortable: false,
          value: "actions"
        }
      ]
    };
  },
  methods: {
    loadCreateStoreApplicationForms() {
      this.$store.dispatch("setIsLoading", true);
      return this.$store
        .dispatch(
          "createStoreApplicationForms/getCreateStoreApplicationForms",
          {
            status: this.status
          }
        )
        .then(() => {
          this.$store.dispatch("setIsLoading", false);
        })
        .catch(err => {
          console.error(err);
          this.$store.dispatch("setIsLoading", false);
          this.$router.replace({ name: "Home" });
        });
    },
    formatTime(time) {
      if (time === "0001-01-01T00:00:00Z") return "-";
      return format(new Date(time), "yyyy/MM/dd HH:mm:ss");
    },
    checkFormIsFilled(form) {
      // text data
      let isFilled =
        !!form["store_name"] &&
        !!form["store_industry_type"] &&
        !!form["owner_name"] &&
        !!form["owner_phone_number"] &&
        !!form["owner_email"] &&
        !!form["store_owner_type"] &&
        !!form["store_phone_number"] &&
        !!form["store_bank_code"] &&
        !!form["store_bank_account"];
      if (form["store_address_detail"]) {
        isFilled =
          isFilled &&
          !!form["store_address_detail"]["postal_code"] &&
          !!form["store_address_detail"]["city"] &&
          !!form["store_address_detail"]["area"] &&
          !!form["store_address_detail"]["road"] &&
          !!form["store_address_detail"]["number"];
      }
      if (form["store_owner_type"] === "company") {
        isFilled = isFilled && !!form["tax_id_number"];
      }
      // photo url
      if (form["photo_urls"]) {
        isFilled =
          isFilled &&
          !!form["photo_urls"]["bank_passbook"] &&
          form["photo_urls"]["bank_passbook"].length >= 1 &&
          !!form["photo_urls"]["store_sign"] &&
          form["photo_urls"]["store_sign"].length >= 3 &&
          !!form["photo_urls"]["environment"] &&
          form["photo_urls"]["environment"].length >= 2;
        if (form["store_owner_type"] === "company") {
          isFilled =
            isFilled &&
            !!form["photo_urls"]["registration_certificate"] &&
            form["photo_urls"]["registration_certificate"].length >= 2 &&
            !!form["photo_urls"]["owner_id_card"] &&
            form["photo_urls"]["owner_id_card"].length >= 2;
        } else if (form["store_owner_type"] === "personal") {
          isFilled =
            isFilled &&
            !!form["photo_urls"]["owner_id_card"] &&
            form["photo_urls"]["owner_id_card"].length >= 2;
        } else {
          isFilled = false;
        }
      } else {
        isFilled = false;
      }
      return isFilled;
    },
    toEditForm(formID) {
      this.$router.push({
        name: "EditCreateStoreApplicationForm",
        params: { id: formID }
      });
    },
    toUploadPhoto(formID) {
      this.$router.push({
        name: "UploadApplyStorePhoto",
        params: { id: formID }
      });
    },
    toSubmitForm(formID) {
      this.$store.dispatch("setIsLoading", true);
      this.$store
        .dispatch(
          "createStoreApplicationForms/submitCreateStoreApplicationForm",
          {
            formID: formID
          }
        )
        .then(() => {
          this.loadCreateStoreApplicationForms();
        })
        .catch(err => {
          this.$store.dispatch("setIsLoading", false);
          console.error("err", err);
          alert(err.message);
        });
    },
    openDeleteDialog(formID) {
      this.cacheID = formID;
      this.deleteDialog = true;
    },
    toDeleteForm(formID) {
      this.$store.dispatch("setIsLoading", true);
      return this.$store
        .dispatch("user/getUserIDToken")
        .then(idToken => {
          return userAPI.deleteCreateStoreApplicationForm(idToken, formID);
        })
        .then(() => {
          this.$store.dispatch("setIsLoading", false);
          this.loadCreateStoreApplicationForms();
        })
        .catch(err => {
          console.error(err);
          this.$store.dispatch("setIsLoading", false);
        });
    },
    toShowReplyDescription(formID) {
      this.descriptionDialog = true;
      this.descriptionDialogText = this.replaceCommaLine(
        this.forms[formID]["reply_description"]
      );
    },
    replaceCommaLine(data) {
      let dataToArray = data.split(" ").map(item => item.trim());
      return dataToArray.join("<br/>");
    }
  },
  created() {
    this.loadCreateStoreApplicationForms();
  }
};
</script>

<style lang="scss">
.title {
  position: relative;
  max-width: 1000px;
  width: 100%;
}
.refresh-btn {
  position: absolute;
  right: 0;
}
</style>

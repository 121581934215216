var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"\n    create-store-application-forms\n    sign-in\n    d-flex\n    flex-column\n    align-center\n    mx-4\n  "},[_c('div',{staticClass:"title mt-16 mb-8 d-flex justify-center"},[_c('div',{staticClass:"text-h4"},[_vm._v(" "+_vm._s(_vm.$t("__create_store_application_forms_title"))+" ")]),_c('v-btn',{staticClass:"refresh-btn mr-4",on:{"click":_vm.loadCreateStoreApplicationForms}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)],1),_c('v-card',{staticClass:"ma-2",attrs:{"elevation":"0","max-width":"1000","width":"100%"}},[_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.formList},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(
                  item['status'] === 'unsubmitted' ||
                    item['status'] === 'rejected'
                )?_c('v-icon',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"small":""},on:{"click":function($event){return _vm.toEditForm(item['id'])}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(item["status"] === "unsubmitted" ? _vm.$t("__edit") : _vm.$t("__refill")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [(item['status'] === 'unsubmitted')?_c('v-icon',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"small":""},on:{"click":function($event){return _vm.toUploadPhoto(item['id'])}}},'v-icon',attrs,false),on),[_vm._v(" mdi-upload ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("__upload_photo")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [(item['status'] === 'unsubmitted' && item['is_filled'])?_c('v-icon',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"small":""},on:{"click":function($event){return _vm.toSubmitForm(item['id'])}}},'v-icon',attrs,false),on),[_vm._v(" mdi-check ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("__submit")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [(item['status'] === 'unsubmitted')?_c('v-icon',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"small":""},on:{"click":function($event){return _vm.openDeleteDialog(item['id'])}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("__delete")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [(
                  (item['status'] === 'accepted' ||
                    item['status'] === 'rejected') &&
                    item['reply_description']
                )?_c('v-icon',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"small":""},on:{"click":function($event){return _vm.toShowReplyDescription(item['id'])}}},'v-icon',attrs,false),on),[_vm._v(" mdi-message-text ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("__reply_description")))])])]}}],null,true)})],1)],1),_c('v-dialog',{attrs:{"max-width":"290"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 red--text"},[_vm._v(" "+_vm._s(_vm.$t("__confirm_delete"))+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"grey darken-1","text":""},on:{"click":function($event){_vm.deleteDialog = false}}},[_vm._v(" "+_vm._s(_vm.$t("__cancel"))+" ")]),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){_vm.deleteDialog = false;
            _vm.toDeleteForm(_vm.cacheID);}}},[_vm._v(" "+_vm._s(_vm.$t("__confirm"))+" ")])],1)],1)],1),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.descriptionDialog),callback:function ($$v) {_vm.descriptionDialog=$$v},expression:"descriptionDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" "+_vm._s(_vm.$t("__reply_description"))+" ")]),_c('v-card-text',{domProps:{"innerHTML":_vm._s(_vm.descriptionDialogText)}}),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.descriptionDialog = false}}},[_vm._v(" "+_vm._s(_vm.$t("__confirm"))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }